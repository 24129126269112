.Conditions {
    min-height: 90vh;
    width: 100%;

    background: -moz-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
    );
    background: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
    );
    background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.92) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );
    color: #fff;
    overflow: hidden;
}

.Conditions-text p{
    text-align: justify;
}

.Conditions-text p span {
    font-weight: bold;
    text-transform: uppercase;
}
.UE-logo {
  height: 50px;
}
.global-footer {
  background-color: #000;
  color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  height: auto !important;
}
.global-footer img {
  max-width: 100%;
}
.Footer-text {
  word-wrap: break-word;
  text-align: center;
  border-bottom: 1px solid #fff;
  width: 80%;
  margin: 0 auto;
}
.Footer-text p {
  margin: 0;
  width: 100%;
}

.Footer-images {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#corfo,
#su,
#lse {
  height: 40px;
  width: 80%;
  margin: 1rem auto;
}
#corfo {
  background-image: url("./img/corfo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
#su {
  background-image: url("./img/SU-Chile.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
#lse {
  background-image: url("./img/lse.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  .Footer-text {
    word-wrap: break-word;
    text-align: right;
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid #fff;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: auto;
  }
  .Footer-text p {
    margin: 0;
    width: 80%;
    margin-left: auto;
    vertical-align: middle;
  }
  .Footer-images {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  #corfo,
  #su,
  #lse {
    height: 40px;
    margin-right: 0.5rem;
  }
  #corfo {
    width: 7rem;
    background-image: url("./img/corfo.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  #su {
    width: 15rem;
    background-image: url("./img/SU-Chile.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  #lse {
    width: 10rem;
    background-image: url("./img/lse.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

#google_translate_element {
  text-align: center;
}
.goog-te-gadget {
  color: white;
}
.goog-logo-link,
.goog-logo-link:link,
.goog-logo-link:visited,
.goog-logo-link:hover,
.goog-logo-link:active {
  color: white;
}

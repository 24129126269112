.ArticleStudie {
  width: 100%;
  /* padding: 24px; */
  box-sizing: border-box;
  /* background-color: rgba(0, 0, 0, 0.2); */
  color: #fff;
  margin-bottom: 2rem;
}
.ArticleStudie a {
  text-decoration: none;
}

.A-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.A-image-container img {
  width: 80%;
  object-position: 50% 50%;
  border: .4rem solid #fff;
  margin-bottom: 2rem;
}

.A-title-container {
  padding: 0 1rem;
  width: 100%;
  margin: 0 auto;
  border: 0.1rem solid #fff;
  border-radius: 0.8rem;
}

.Article-title {
  word-wrap: break-word;
  text-align: center;
  color: #fdc41a;
  font-size: 1.5rem;
}
.Article-title::after,
.Article-title::before {
  content: ' " ';
  display: inline-flex;
  margin: 0 0.1rem;
}

.A-download-container {
  color: #fdc41a;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.A-download-container i {
  font-size: 1.8rem;
}

.A-donwload {
  text-transform: uppercase;
  font-size: 1.1rem;
  color: #fdc41a;
  font-weight: 100;
}

.A-donwload:hover {
  color: #fdc41a;
  text-decoration: underline;
}

.A-body-title {
  text-align: center;
  margin-bottom: 1rem;
}

.A-body-text {
  text-align: justify;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .A-image-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .A-image-container img {
    width: 100%;
    position: absolute;
    object-position: 50% 50%;
    border: 0.1rem solid #fff;
  }

  .A-title-container {
    padding: 0 0.5rem;
    width: max-content;
    margin: 0 auto;
    border: 0.1rem solid #fff;
    border-radius: 0.8rem;
    max-width: 100%;
  }

  .Article-title {
    font-size: 1.75rem;
  }

  .A-download-container i {
    font-size: 1.2rem;
  }

  .A-donwload {
    text-transform: uppercase;
    font-size: 1rem;
    color: #fdc41a;
    font-weight: 100;
  }
}

/* // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */
.Product {
  min-height: 90vh;
  width: 100%;

  /*
    * 
    * gradient - http://colorzilla.com/gradient-editor/#000000+0,000000+50,000000+100&0.92+0,0.82+50,0.92+100 
    * 
    **/

  background: -moz-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.92) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );
  color: #fff;
  overflow: hidden;
}

nav.Product-nav {
  width: 100%;
  margin-top: 15%;
}

nav .nav-tabs {
  width: 100%;
  align-items: center;
  justify-content: space-around;
  border-bottom: none !important;
}

.nav-tabs .nav-link {
  color: #fdc41a;
  background-color: #000;
  align-self: flex-start;
  border-radius: 0.35rem;
  position: relative;
  width: 10rem;
  text-align: center;
  word-break: break-all;
}

.nav-tabs .nav-link::after {
  position: absolute;
  content: "";
  height: 2px;
  background: #fdc41a;
  margin: 0;
  left: 100%;
  top: 1.3rem;
  width: 150%;
}

.nav-tabs .nav-link:last-child::after {
  position: absolute;
  content: "";
  height: 2px;
  background: #fdc41a;
  margin: 0;
  left: 100%;
  top: 1.3rem;
  width: 100%;
}

.nav-tabs .nav-link:first-child::before {
  position: absolute;
  content: "";
  height: 2px;
  background: #fdc41a;
  margin: 0;
  right: 100%;
  top: 1.3rem;
  width: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.nav-tabs .nav-link.active {
  background-color: #fdc41a;
  border-color: #fdc41a;
  color: #000;
  height: 4rem !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-image {
  position: absolute;
  top: -6.2rem;
  left: 0;
  right: 0;
  width: 100%;
  height: 5.5rem;
}

.tab-image#img-mapping {
  background: url("./img/mapa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.tab-image#img-prediction {
  background: url("./img/grafico.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.tab-image#img-impact {
  background: url("./img/diana.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.tab-content {
  background: rgba(0, 0, 0, 0.2);
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 1rem;
}

.tab-content .tab-pane {
  width: 100%;
}

@media (min-width: 992px) {
  .tab-content .tab-pane {
    width: 90%;
    margin: 0 auto;
  }
}

/* Movil version */

.services{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.services-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#mapping, #prediction, #impact {
  width: 100%;
  min-height: 60vh;
}

.services-img{
  width: 100%;
  height: 15rem;
}

#mapping-image {
  background: url("./img/mapa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#prediction-image {
  background: url("./img/grafico.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#impact-image {
  background: url("./img/diana.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.services-title-container{
  box-sizing: border-box;
  border-radius: 0.35rem;
  background-color: #000;
  padding: 1rem;
  width: 80%;
}

.services-title {
  text-align: center;
  color: #fdc41a;
  text-transform: uppercase;
}

.services-text {
  padding-top: 2rem;
  text-align: justify;
}
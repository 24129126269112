@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/*
*
* General
*
**/
.Home-section {
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 65vh;
  display: flex;
  align-items: center;
}
.Home-section img {
  width: 100%;
}
.Home-info h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;
}

/*
*
* Section 1
*
**/
.Home-section#section-1 {
  background-color: rgb(26, 27, 28);
}
.Home-info#info-1 {
  color: #fff;
  width: 100%;
}

.img-comp-slider > div:nth-child(odd) {
  background-color: #fdc41a !important;
}

.img-comp-slider > div:nth-child(2) {
  border-color: #fdc41a !important;
  color: #fdc41a !important;
}
.img-comp-slider > div:nth-child(2) div:first-child {
  border-color: rgba(0, 0, 0, 0) #fdc41a rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
}
.img-comp-slider > div:nth-child(2) div:last-child {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fdc41a !important;
}

.slick-prev,
.slick-next {
  height: auto;
  width: auto;
  z-index: 1;
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
}

.slick-next:before,
.slick-prev:before {
  font-size: 4rem;
}

#ub-title {
  font-size: 1.8rem;
}

.slick-next:before {
  font-family: "Font Awesome 5 Free" !important;
  content: "\f0da";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-weight: 900;
}

.slick-prev:before {
  font-family: "Font Awesome 5 Free" !important;
  content: "\f0d9";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-weight: 900;
}

/*
*
* Section 2
*
**/
.Home-section#section-2 {
  background-color: #fff;
  color: #000;
  position: relative;
}
.Home-section#section-2 .section-image {
  object-fit: contain;
}

/*
*
* Section 3
*
**/
.Home-section#section-3 {
  background-color: rgb(26, 27, 28);
  background-image: url("./img/home3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
}
.Home-info#info-3 {
  color: #fff;
}

/*
*
* Section 4
*
**/
.Home-section#section-4 {
  background-color: #fff;
}

.Home-section#section-4 img {
  max-height: 50vh;
  width: auto;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #ub-title,
  .Home-info h3 {
    font-size: 2rem;
  }

  .slick-next {
    right: -2rem;
  }

  .slick-prev {
    left: -2rem;
  }
}

/* Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... } */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #ub-title,
  .Home-info h3 {
    font-size: 2.5rem;
  }

  .Home-info#info-1 {
    margin: 0 auto;
  }

  .Home-section#section-4 img {
    max-height: 70vh;
    width: auto;
  }
}

/* Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */

.color {
  height: 16px;
  width: 16px;
  display: inline-block;
  border: 1px solid black;
}

.color-lg {
  width: 100%;
}

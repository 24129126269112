.global-navbar {
  background: #000;
  color: #fff;
}

.global-navbar a:not(.btn) {
  color: #ccc;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.global-navbar a:hover {
  color: #eee;
}

.global-navbar .nav-link {
  border-bottom: 0.2rem solid transparent;
  color: #ccc;
  font-size: 0.78em;
  text-transform: uppercase;
  cursor: pointer;
}

.global-navbar .nav-link:hover,
.global-navbar .nav-link.active {
  color: #eee;
  border-bottom: 0.2rem solid #fdc41a;
}
.navbar-toggler i {
  color: white;
}

.global-navbar .dropdown .dropdown-menu {
  background-color: #222;
  padding: 0 0.5rem;
}

.global-navbar .dropdown .dropdown-menu {
  left: auto;
  right: 0;
}
.global-navbar .dropdown {
  display: inline-block;
  vertical-align: middle;
}

.Register {
  min-height: 90vh;
  width: 100%;
  background-image: url("./img/bg_register.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
}

.Register .container-box {
  padding: 2rem 0;
  margin-bottom: 2rem;
}
.Register .container-box .title {
  font-size: 1.1em;
  font-weight: bold;
  color: #666;
  margin-bottom: 1em;
  text-transform: uppercase;
}
.Register .container-box .arrow img {
  width: 100%;
}
.Register .container-box .field-input {
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
}
.Register .container-box .field-input label {
  margin: 0;
  font-weight: bold;
  color: #666;
}
.Register .container-box .field-input input {
  background-color: #aeaeae;
  border: none;
  border-radius: 2px;
  outline: none;
  padding: 0.2em 0.4em;
  font-family: inherit;
  font-size: 0.9em;
}

.Register .container-box .field-select {
  margin: 0.8em 0;
}
.Register .container-box .field-select label {
  font-weight: bold;
  display: block;
  margin: 0;
}
.Register .container-box .field-select select {
  background-color: #555;
  color: #eee;
  border: none;
  border-radius: 2px;
  min-width: 100px;
  font-family: inherit;
  font-size: 0.9em;
  outline: none;
  padding: 0 0.5em;
}
.Register .container-box .field-select option:hover {
  box-shadow: 0 0 10px 100px #fdc41a inset;
}

.Register .container-box .field-selectable {
  margin: 0.8em 0;
}
.Register .container-box .field-selectable > label {
  font-weight: bold;
  display: block;
  margin: 0;
}
.Register .container-box .field-selectable .selectable label {
  font-weight: bold;
  margin: 0;
  font-size: 0.9em;
}
.Register .container-box .field-selectable .selectable input[type="radio"] {
  appearance: none;
  outline: none;
  height: 0.8em;
  width: 0.8em;
  background-color: #555;
  border-radius: 50%;
  margin-right: 0.8em;
  position: relative;
}
.Register
  .container-box
  .field-selectable
  .selectable
  input[type="radio"]:before {
  content: "";
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  background-color: transparent;
  height: 0.3em;
  width: 0.3em;
  border-radius: 50%;
}
.Register
  .container-box
  .field-selectable
  .selectable
  input[type="radio"]:checked:before {
  background-color: #fff;
}

.Register .container-box .field-selectable .selectable input[type="checkbox"] {
  appearance: none;
  outline: none;
  height: 0.8em;
  width: 0.8em;
  background-color: #555;
  margin-right: 0.8em;
  position: relative;
  font-size: 0.8em;
}
.Register
  .container-box
  .field-selectable
  .selectable
  input[type="checkbox"]:after {
  content: "";
  position: absolute;
  background-color: transparent;
  height: 0.8em;
  width: 0.8em;
  top: 0;
}
.Register
  .container-box
  .field-selectable
  .selectable
  input[type="checkbox"]:checked:after {
  content: "\2713";
  color: #fff;
  top: -0.3rem;
  left: -0.02rem;
}

.Register .container-box .text {
  font-size: 0.9em;
  text-align: justify;
  line-height: 1.2em;
}
.Register .container-box .text span {
  font-weight: bold;
  text-transform: uppercase;
}

.Register .container-box .btn {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #fdc41a;
  border-color: #fdc41a;
}

.text-ub-color {
  color: #fdc41a !important;
}

.condition-link {
  padding-top: 1rem;
}
.condition-link a {
  /* color: #fdc41a; */
  text-transform: uppercase;
  font-weight: bolder;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .Register .container-box {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 2em 4em;
    margin-bottom: 0;
  }
}

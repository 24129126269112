.PublicTest {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;

  flex-flow: column;
  background-size: cover;
}
.PublicTest p {
  text-align: justify;
}
.PublicTest .btn {
  margin: 4px 10px;
  box-sizing: border-box;
  font-size: 3em;
  line-height: 100%;
  padding: 0.5em 1em;
  border-radius: 10px;
  width: 400px;
  max-width: 90vw;
}
@media (max-width: 600px) {
  .PublicTest .btn {
    font-size: 2em;
  }
}
.intro {
  background: rgba(43, 37, 37, 0.5);
  padding: 1rem;
  box-sizing: border-box;

  color: white;
  margin: 5vh 0;
}
.PublicTest img {
  max-width: 100%;
  width: 300px;
  padding: 0 1em 0 0;
}
@media (max-width: 600px) {
  .PublicTest img {
    padding: 0 0 1em 0;
  }
}
.enlaces {
  text-align: center;
}

.color-urban {
  color: #fdc41a;
}

#map-container {
  width: 100%;
  height: auto !important;
  position: relative;
  display: flex;
  flex-direction: column;
}

#map {
  width: 100%;
  height: 20rem;
  overflow: auto;
}
#place-container {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
#places > div {
  position: relative;
  margin-top: 1rem !important;
}

#places > div img {
  width: 100%;
  height: auto;
}

/* #places > div button {
    cursor: pointer;
    top: -.5rem;
    right: 12%;
    position: absolute;
    color: #fff;
    background-color: #ff2e00;
    border-color: transparent;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

#places > div button:hover {
    background-color: #fdc41a;
    color: #000;
 } */

#places > div button {
  cursor: pointer;
  top: 0;
  right: 0;
  position: absolute;
  background-color: #fdc41a;
  color: #000;
  border-color: transparent;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 3px;
}

#places > div button:active,
#places > div button:focus {
  outline: none;
}

#places > div button i {
  font-size: 2rem !important;
}

.field-select select {
  background-color: #555 !important;
  color: #eee !important;
  border: none;
  border-radius: 2px;
  min-width: 100px;
  font-family: inherit;
  font-size: 0.9em;
  outline: none;
  padding: 0 0.5em;
  width: 100%;
}

.urban-lista {
  width: 100%;
  overflow-x: scroll;
}
.image-number {
  position: absolute;
  top: calc(50% - 2em);
  box-sizing: border-box;
  padding: 0.5em;
  background: #fdc419;
  color: black;
  font-weight: bold;
  border-radius: 0 6px 6px 0;
}

@media (min-width: 992px) {
  .field-select select {
    width: 45%;
  }

  #map-container {
    width: 100%;
    height: 20rem;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  #map {
    width: 100%;
    height: 20rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    overflow: auto;
  }
  #place-container {
    box-sizing: border-box;
    padding-bottom: 1rem;
    height: 20rem;
    background-color: #555;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: auto;
  }

  #places > div .image-number {
    display: inherit;
    top: calc(50% - 0.45rem);
    left: 0;
    position: absolute;
    background-color: #fdc41a;
    color: #000 !important;
    border-radius: 1rem;
    font-size: 0.9rem;
    line-height: 0.45;
    padding: 0.45rem;
  }

  #places > div button {
    cursor: pointer;
    top: -0.5rem;
    right: 12%;
    position: absolute;
    color: #fff;
    background-color: #ff2e00;
    border-color: transparent;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  #places > div button:hover {
    background-color: #fdc41a;
    color: #000;
  }

  #places > div img {
    width: 70%;
    height: auto;
  }

  #places > div button i {
    font-size: 1rem !important;
  }
}

@media (min-width: 1200px) {
  .field-select select {
    width: 40%;
  }
}

.btn-content button {
  background-color: #555;
  border-color: #555;
  color: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
  text-transform: lowercase;
}

.btn-content button:focus {
  box-shadow: none !important;
}

.btn-content button:hover,
.btn-content button:active {
  background-color: #777 !important;
  border-color: #777 !important;
}
.MapsImages small {
  margin-left: 1em;
}

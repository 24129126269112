.HeatmapImage {
  position: relative;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background: black;
  height: 720px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading i {
  font-size: 5em;
}

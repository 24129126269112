.Page404 {
  min-height: 70vh;
  background-color: #1a1b1b;
}
.Page404 h1 {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

.btn-404 {
  border-radius: 10em;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
  margin: 0.375rem auto;
  border: 0;
  position: relative;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.84rem 2.14rem;
  font-size: 0.81rem;
  background-color: #fdc41a;
  cursor: pointer;
  width: 40%;
  transition-property: color, background-color, border-color, box-shadow,
    -webkit-box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out,
    ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s, 0s;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow,
    -webkit-box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out,
    ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s, 0s;
}
.btn-404:hover {
  color: #fff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  outline: 0;
  text-decoration: none;
  background-color: #feb80e;
  transition: all 0.2s ease-in-out;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}

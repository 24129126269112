.canvas-container {
    height: 50vh;
}

.canvas-container#last-canvas {
    height: 60vh;
}

/* Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... } */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .canvas-container, .canvas-container#last-canvas {
        height: 40vh;
    }
}

/* Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... } */

/* Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */
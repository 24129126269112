.FormLogin {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 24px;
  border-radius: 1rem;
}

.FormLogin-container {
  width: 90%;
  margin: 0 auto;
}

.FormLogin input,
.FormLogin button {
  width: 100%;
  margin-top: 1rem;
  color: #000;
  border-radius: 0;
}

.FormLogin-input,
.FormLogin-btn {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  height: auto;
}

.FormLogin-btn {
  background-color: #fdc41a;
  border-color: #fdc41a;
}

.FormLogin p {
  text-align: center;
}

.FormLogin a {
  text-decoration: none;
  color: #fff;
}

.FormLogin .btn-register {
  text-transform: uppercase;
  background-color: #979797;
  border-color: #979797;
}
.Client {
  min-height: calc(100vh - 200px);
  width: 100;
  background-image: url("./img/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.canvasContainer {
    position: relative;
}


.canvasContainer img {
    width: 1280px;
    height: 720px;
}

.canvasContainer canvas {
    position: absolute;
    top: 0;
    left: 0;
}

#canvasController ul {
    padding: 0;
    list-style: none;
}

#canvasController ul li::before{
    content: '/';
    padding-right: .5rem;
    display: inline;
}

#canvasController select{
    background-color: #555 !important;
    color: #eee !important;
    border: none;
    border-radius: 2px;
    min-width: 7rem;
    font-family: inherit;
    font-size: 0.9em;
    outline: none;
    padding: 0 0.5em;
}
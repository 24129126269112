.Contact {
  min-height: 110vh;
  width: 100%;
  background-image: url("./img/bg_contact.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: -1px;
}

.Contact-input,
.Contact-textarea {
  background-color: #d8d8d8;
  border-color: #c4c4c4;
  border-radius: 0;
}

.Contact-input::placeholder,
.Contact-textarea::placeholder {
  color: #5a5a59;
}

.Contact-textarea {
  height: 8rem !important;
  resize: none;
}

.Contact-btn {
  border-radius: 5px;
  background-color: #fdc41a;
  border-color: #fdc41a;
  text-transform: uppercase;
  min-width: 10rem;
  font-weight: bold;
}

.Contact-prepend {
  background-color: #d8d8d8;
  border-color: #c4c4c4;
  border-radius: 0;
  border-right: none;
}

.Contact-input {
  border-left: none;
}
.btn.continue:hover {
  background-color: #888 !important;
  border-color: #777 !important;
}

.pregunta {
  text-align: center;
}
.images {
  text-align: center;
}
.scores {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.TestWrapper-onthefield {
  cursor: none;
}

.TestWrapper-onthefield .btn:hover,
.TestWrapper-onthefield ul.circles li:hover {
  cursor: none !important;
}

.TestWrapper-onthefield .btn,
.TestWrapper-onthefield ul.circles li {
  cursor: none;
}
/* black : #020303 */
.Test {
  width: 100%;
}

.Test.dark-bg {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background: -moz-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.92) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );
  color: #fff;
}

.Test.grey-bg {
  min-height: 80vh;
  background: rgb(242, 242, 242);
  background: linear-gradient(
    45deg,
    rgba(242, 242, 242, 1) 0%,
    rgba(205, 205, 205, 1) 100%
  );
}

.Test.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Test .title {
  font-size: 1.4em;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.Test .description {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2.5rem;
}
.Test .description2 {
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2.5rem;
}
.Test .description3 {
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.Test .description4 {
  font-size: 1.1em;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 1rem;
}

.Test .illustration {
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Test .box-title {
  margin-bottom: 0.5em;
  font-weight: bold;
}
.Test .box-description {
  font-size: 0.9em;
  color: #fff;
  padding: 0 2em;
  margin: 0 auto;
}
.Test .btn-continue {
  min-width: 200px;
  text-transform: uppercase;
  font-weight: bold;
}
.Test .btn-continue:not([disabled]) {
  background-color: #fdc41a;
  border-color: #fdc41a;
}
.Test .range {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
.Test .range .text1,
.Test .range .text2 {
  font-weight: bold;
  color: #666;
}
.Test .range ul.circles {
  display: flex;
  list-style: none;
  justify-content: space-between;
  flex: 2;
  width: 100%;
  padding: 0 1em;
  margin: 0;
}
.Test .range ul.circles li {
  height: 4em;
  width: 4em;
  background-color: #888;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  transition: 0.2s;
  cursor: default;
  user-select: none;
  font-size: 1.2em;
}
.Test .range ul.circles li:hover,
.Test .range ul.circles li.active {
  transform: scale(1.5);
  background-color: #fdc41a;
  color: #666;
}
.Test .image {
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 2em;
  background: #333;
}
.Test .image img {
  width: 100%;
  object-fit: contain;
}
.Test .big-image {
  overflow: hidden;
  margin-bottom: 2em;
}
.Test .big-image img {
  width: 100%;
}
.Test .arrow img {
  width: 100%;
}

.Agra {
  min-height: auto;
  width: 100%;
  background: -moz-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.92) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );
}

.Agra-title-content {
  box-sizing: border-box;
  width: 18rem;
  height: 18rem;
  background-color: #fdc41a;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
}

.Agra .Agra-title {
  font-family: "Times New Roman", Times, serif;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.1rem;
}

.Agra .Agra-text {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 3rem;
}

.Agra .Agra-text span.text-focus {
  font-weight: bold;
  border-bottom: 0.1rem solid #fdc41a;
}

.instructions-no-eye div {
  margin-bottom: 3rem;
}

.inst-img img {
  width: 50%;
}

.ints-arrow-v img {
  height: 8rem;
}

#rotate {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#rotate img {
  width: 90%;
  margin-bottom: 2rem;
}

.newScore {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newScore .head-question {
  width: 100%;
  box-sizing: border-box;
  background-color: #fdc41a;
  color: #444;
  text-align: center;
}

.newScore .head-question p {
  font-size: 1.3rem;
  font-weight: bolder;
  margin-bottom: 0;
}

.newScore .img-container {
  position: relative;
  height: 90vh;
  width: 100%;
  background: #333;
}

.newScore .img-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.newScore .range {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.newScore .range .text1,
.newScore .range .text2 {
  width: 15%;
  word-wrap: normal;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: #fff;
}

.newScore .range .circles {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Test .newScore .range .circles li {
  color: #888;
  background: #fff;
  cursor: pointer;
  font-size: 1.4rem;
  height: 2.5rem;
  width: 2.5rem;
}

.Test .newScore .range .circles li:hover {
  transform: none;
  background-color: #fdc41a;
  color: #fff;
}

@media screen and (orientation: landscape) {
  #rotate img {
    width: 50%;
    margin-bottom: 2rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) { ... } */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Agra-title-content {
    height: 20rem;
    width: 20rem;
    box-sizing: border-box;
    background-color: #fdc41a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
  }

  .Agra .Agra-title {
    font-family: "Times New Roman", Times, serif;
    font-weight: bolder;
    text-align: center;
    text-transform: uppercase;
    font-size: 2.8rem;
  }

  .Agra .Agra-text {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 5rem;
  }

  .Agra .Agra-text span.text-focus {
    font-weight: bold;
    border-bottom: 0.1rem solid #fdc41a;
  }
}

@media (min-width: 992px) {
  .newScore .head-question p {
    font-size: 1.6rem;
    font-weight: bolder;
    margin-bottom: 0;
  }

  .newScore .range .text1,
  .newScore .range .text2 {
    width: 15%;
    word-wrap: break-word;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #fff;
  }

  .newScore .range .circles {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Test .newScore .range .circles li {
    color: #888;
    background: #fff;
    cursor: pointer;
    font-size: 1.8em;
    height: 3.6rem;
    width: 3.6rem;
  }
  .Agra {
    min-height: auto;
    width: 100%;
    background: -moz-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0.9) 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0.9) 100%
    );
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 0.92) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );
  }

  .Agra-title-content {
    box-sizing: border-box;
    margin-top: 4rem;
    width: 23rem;
    height: 23rem;
    background-color: #fdc41a;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
  }

  .Agra .Agra-title {
    font-family: "Times New Roman", Times, serif;
    font-weight: bolder;
    text-align: center;
    text-transform: uppercase;
    font-size: 3.2rem;
  }

  .Agra .Agra-text {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 5rem;
  }

  .Agra .Agra-text span.text-focus {
    font-weight: bold;
    border-bottom: 0.1rem solid #fdc41a;
  }
}

@media only screen and (max-height: 680px) {
  .Test .title,
  .Test .box-title,
  .Test .illustration,
  .hoverButton button {
    margin: 0;
  }
}

.cargando {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-height: 80vh;
}

.cargando i {
  font-size: 4em;
}
.cargando h3 {
  margin-top: 1em;
}

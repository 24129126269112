.sidemenu {
  border-left: 0.3rem solid #fdc41a;
}

.sidemenu li {
  background: transparent;
  padding: 0.5rem 0.75rem;
  border: none;
}

.sidemenu li:first-child {
  padding-top: 0;
}

.sidemenu li a {
  color: #ccc;
}

.sidemenu li a.active,
.sidemenu li a:hover {
  text-decoration: none;
  color: #fdc41a !important;
}

.Admin-bg {
  width: 100%;
  min-height: 100vh;

  background: -moz-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.92) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );

  color: #fff;
}
.table {
  color: #fff !important;
}

/*
* 
* estilos admin defectos
* 
**/
.field-select select,
.field-input input {
  background-color: #555 !important;
  color: #eee !important;
  border: none;
  border-radius: 2px;
  min-width: 100px;
  font-family: inherit;
  font-size: 0.9em;
  outline: none;
  padding: 0 0.5em;
  width: 100%;
}

.field-input input::placeholder {
  color: #eee;
}

.btn-urban {
  color: #000 !important;
  background-color: #fdc41a !important;
}

.urban-lista {
  background-color: #555;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.urban-lista > h2,
.urban-lista > table {
  padding: 0.75rem;
}

.urban-lista > table {
  text-align: center;
}

.urban-lista > table thead {
  background-color: #333;
  line-height: 3;
  border-bottom: 2px solid #dee2e6;
}
.table thead th {
  border-bottom: 0px;
}

.urban-lista a {
  color: #fdc41a;
}
.urban-lista table tbody tr td {
  vertical-align: middle;
}

.up-images #file {
  display: none;
}

.up-images .btn-outline {
  text-transform: uppercase;
  display: block;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}

/*
*
* check
*
**/

.ckb {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.ckb > input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.ckb > i {
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.5;
  transition: 0.2s;
  margin-top: 0.5rem;
}

.ckb > input + i:after {
  content: "\f111";
  display: block;
  transition: inherit;
}
.ckb > input:checked + i:after {
  content: "\f058";
  display: block;
}

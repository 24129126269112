.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-height: 80vh;
}

.logout h3 {
  margin-top: 1em;
}

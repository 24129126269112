.ExperimentList {
  padding-top: 2em;
}
.ExperimentList .experimento {
  border: 3px solid white;
  display: block;
  margin: 1em;
  background: #222;
  text-align: center;
  padding: 1em;
  background-size: cover;
}

.ExperimentList .experimento a {
  text-decoration: none;
  color: white;
}
.ExperimentList .descripcion {
  height: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: justify;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 1em;
}
.ExperimentList .institucion {
  display: flex;
  flex-flow: column;
  height: 10rem;
  box-sizing: border-box;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
}

.ExperimentList h2 {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
}
.ExperimentList .urban-btn {
  width: 100%;
  margin-top: 1em;
}
.ExperimentList small {
  margin-top: 1em;
}
